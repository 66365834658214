<template>
  <div>
    <a-card title="Полученные чаевые" class="mb-3">
      <template #extra>
        <a-form layout="inline">
          <a-form-item>
            <a-select
              show-search
              :filter-option="filterOption"
              style="width: 175px"
              placeholder="Выберите заведение"
              v-model:value="filterStoreId"
              :options="filterStores"
              @change="storeFilter"
            ></a-select>
          </a-form-item>
          <a-form-item>
            <a-select
              show-search
              :filter-option="filterOption"
              style="width: 175px"
              placeholder="Выберите сотрудника"
              v-model:value="filterEmployeeId"
              :options="filterEmployees"
              @change="employeeFilter"
            ></a-select>
          </a-form-item>
          <a-form-item>
              <a-select
                      show-search
                      :filter-option="filterOption"
                      style="width: 175px"
                      placeholder="Выберите тип"
                      v-model:value="filterType"
                      :options="[
                          {
                              label: 'Чаевые',
                              value: 'tips'
                          },
                          {
                              label: 'Доставка',
                              value: 'delivery',
                          },
                          {
                              label: 'Бонус',
                              value: 'bonus',
                          },
                      ]"
                      @change="typeFilter"
              ></a-select>
          </a-form-item>
          <a-form-item class="mr-0">
            <a-button @click="resetFilter">Сбросить</a-button>
          </a-form-item>
        </a-form>
      </template>
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        @change="tableChange"
      >
        <template #date="{ text }">
          {{ formattedDate(text) }}
        </template>
        <template #store="{ record }">
          {{ record.store.name }}
        </template>
        <template #employee="{ record }">
          <div v-if="record.employee">
            <div v-if="record.employee.photo_url === '' || record.employee.photo_url === null" class="employee-photo">
              <img width="47" src="https://cdn.multicard.uz/storage/29e/e8576059ab6c74182ab7797992857.png" alt="">
            </div>
            <div v-else :style="getBackground(record.employee.photo_url)" class="employee-photo"></div>
            <span class="employee-name">{{ record.employee.name }}</span>
          </div>
        </template>
        <template #amount="{ record }">
          {{ formattedBalance(record.amount) }}
        </template>
        <template #type="{ text }">
          <a-tag color="green">{{ typeName(text) }}</a-tag>
        </template>
      </a-table>
      <div class="mt-4 text-right">
        <a-pagination
          show-size-changer
          v-if="total > query['per-page'].value"
          v-model:pageSize="query['per-page'].value"
          v-model:current="query.page.value"
          :total="total"
        />
      </div>
    </a-card>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
import apiClient from "@/services/axios";
import { mask } from 'maska';
import moment from "moment";
import {useRoute, useRouter} from "vue-router";

let
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    store_id: '',
    employee_id: '',
  },
  dataSource = ref([]),
  loading = ref(false),
  queryParams = reactive({...initialParams}),
  stores = ref([]),
  filterStores = ref([]),
  filterEmployees = ref([]),
  filterStoreId = ref(null),
  filterEmployeeId = ref(null),
  filterType = ref(null),
  total = ref(0);

const router = useRouter(),
  route = useRoute(),
  columns = [
    {
      title: 'Дата',
      dataIndex: 'added_on',
      key: 'added_on',
      sorter: true,
      slots: {
        customRender: 'date',
      },
    },
    {
      title: 'Заведение',
      dataIndex: 'store',
      key: 'store_id',
      sorter: true,
      slots: {
        customRender: 'store',
      },
    },
    {
      title: 'Сотрудник',
      dataIndex: 'employee',
      key: 'employee_id',
      slots: {
        customRender: 'employee',
      },
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      slots: {
        customRender: 'amount',
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      slots: {
        customRender: 'type',
      },
    },
  ];

const tableChange = (pag, filters, sorter) => {
  if (typeof sorter.order == "string") {
    let sign = ''
    if (sorter.order === 'descend')
      sign = '-'
    updateParams({sort: sign + sorter.field})
  }
};

const updateParams = params => {
  Object.assign(queryParams, params)
};

const formattedPhone = (phone) => {
  return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
}

const formattedDate = (value) => {
  let diff = moment(value).diff(moment(), 'days');
  let uint = number =>  Math.sqrt(Math.pow(number, 2));
  let date;
  if (uint(diff) > 1) {
    date = moment(value).locale('ru').format('YYYY.MM.DD в HH:mm')
  } else {
    date = moment(value).locale('ru').calendar()
  }
  return date;
}

const typeName = type => {
  if (type === 'tips')
    return 'Чаевые'
  if (type === 'delivery')
    return 'Доставка'
  if (type === 'bonus')
    return 'Бонус'
}

const
  storeFilter = (e) => {
    filterStoreId.value = e
    updateParams({store_id: e})
  },
  employeeFilter = (e) => {
    filterEmployeeId.value = e
    updateParams({employee_id: e})
  },
  typeFilter = (e) => {
    filterType.value = e
    updateParams({type: e})
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get(`/report/income`, { params }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
      stores.value = data.data.stores
      filterStores.value = data.data.filter.stores
      filterEmployees.value = data.data.filter['employees']
      let storesList = []
      data.data['stores'].forEach((store) => {
        storesList.push({
          text: store.name,
          value: store.id,
        })
      })
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  formattedBalance = (balance) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = formatter.format(balance / 100)
    return formattedBalance.substring(0, formattedBalance.length - 4)
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
    getData({
        ...queryParams,
    })
})

watch(queryParams, () => {
    router.push({
        name: 'income',
        query: {
            ...route.query,
            ...queryParams,
        },
    })
    getData({
        ...route.query,
        ...queryParams,
    })
})

const resetFilter = () => {
  filterStoreId.value = null
  filterEmployeeId.value = null
  filterType.value = null
  Object.assign(queryParams, initialParams);
}

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

const getBackground = (url) => {
  return `background-image: url('${url}')`;
}

const query = {...toRefs(queryParams)}

</script>

<style scoped src="./withdraw.css"></style>
